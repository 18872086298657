import React from 'react'
import styles from './Main.module.scss'
import { InitialPage } from '../../components/initialPage/InitialPage'
import { Advantages } from '../../components/Advantages/Advantages'
import {Callback} from '../../components/Callback/Callback'
import { Scheme } from '../../components/Scheme/Scheme'
import { Feedback } from '../../components/Feedback/Feedback'
import {Portfolio} from '../../components/Portfolio/Portfolio'
import {Questions} from '../../components/Questions/Questions'
import {VideoClip} from '../../components/VideoClip/VideoClip'
import {Services} from "../../components/Services/Services"
import {SliderAdvantages} from '../../components/SliderAdvantages/SliderAdvantages'
import { FindPrice } from '../../components/FindPrice/FindPrice'

export const Main = ({isOpen, popupOpen, isImageOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage
                isOpen ={isOpen}
            />
            <SliderAdvantages/>
            <Services
                isOpen ={isOpen}
            />
            <Advantages/>
            <Portfolio
                isImageOpen={isImageOpen}
            />
            <SliderAdvantages/>
            <VideoClip/>
            <Scheme
                isOpen ={isOpen}
            />
            <FindPrice/>
            <Feedback/>
            <Questions/>
            <Callback
                popupOpen={popupOpen}
            />
        </section>
    )
}