import React from 'react'
import styles from './Feedback.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import {  Pagination, Autoplay} from "swiper"
import "./Feedback.css"
import 'swiper/scss'
import "swiper/scss/pagination"
import "swiper/scss/navigation"
import img from '../../image/Feedback/img.webp'



export const Feedback = () => {


    return (
        <section className={styles.main} id='Septic'>
            <div className={styles.box}>
                <h2 className={styles.title}><span>Отзывы</span> наших клиентов</h2>
                    <div className={styles.slider}>
                        <Swiper
                                spaceBetween={10}
                                slidesPerView={1.3}
                                centeredSlides={true}
                                loop={true}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    type: 'fraction'
                                }}
                                breakpoints={{

                                    1400: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 5,
                                    },

                                    768: {
                                        slidesPerView: 1.2,
                                        spaceBetween: 5,
                                    },

                                    425: {
                                        slidesPerView: 1.1,
                                        spaceBetween: 5,
                                    },

                                    320: {
                                        slidesPerView: 1.05,
                                        spaceBetween: 5,
                                    },
                                }}
                                modules={[Pagination, Autoplay]}
                                className="mySwiper"
                                >
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Иван</p>
                                        <p className={styles.comment}>07.09.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Приехали быстро, сработали оперативно и качествено. По телефону, цена за услуги была названа самая низкая. Никаких доплат за какие-то выдуманные сложности по факту выполнения работ не запросили... Рекомендую заказывать услуги у этой компании! Спасибо!!!</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Александр</p>
                                        <p className={styles.comment}>14.08.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Вызывал илосос, сработали четко, приехали быстро…Думал между ассенизатором и илососом, хоть второй и дороже ребята очистили колодец под ноль, теперь стоки накапливаются намного дольше чем раньше…улучшилась всасываемость. Теперь не надо так часто вызывать по копейкам, экономия на перспективу. Рекомендую 👍</p>                              
                                    </div>
                                </div>  
                            </SwiperSlide>
                            <SwiperSlide>
                            <div className={styles.cell}>
                                <div className={styles.item}>
                                    <p className={styles.subtitle}>Катерина</p>
                                    <p className={styles.comment}>19.7.2023</p>
                                    <img className={styles.image} src={img} alt = 'звездочки'/>
                                    <p className={styles.comment}>Комментарий</p>
                                    <p className={styles.text}>Супер ребята! Позвонила, все объяснили как и что будут делать, приехали в этот же день, полностью размыли дно септика от старых окаменелых отложений ! Очень приятные в общении и профессионалы своего дела! Была проблема с септиком-не уходила вода, откачивали каждые 20 дней, после промывки прошло 6 дней, вода даже не накапливается, полностью уходит в песок. Очень рада, что обратилась в вашу компанию, буду рекомендовать знакомым и соседям!</p>                              
                                </div>
                            </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Роман</p>
                                        <p className={styles.comment}>14.05.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Всё отлично.цена,качество,скорость. особая благодарность ребятам которые работали в Мытищах. Очень грамотный подход,качественно и быстро сделали свою работу,всё чисто и без лишних слов. до этого приезжали разные фирмы.Эту бригаду и эту фирму однозначно могу посоветовать!!!👍👍👍</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Александр Попов</p>
                                        <p className={styles.comment}>1.03.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Ребята профессионалы, без сомнений. Оговорённая цена по телефону не изменилась. Приветливые, отзывчивые. На все мои дилетантские вопросы доходчиво всё объясняли. Оборудование промышленное, с гидро мойкой. За 7-8 минут убрали ил, дали рекомендации.</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Алексей</p>
                                        <p className={styles.comment}>18.06.2022</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Случай был тяжелый. Ил удалили, но пошли крупные камни, которые забивали трубы. Все размыли и откачали из двух ям. Думаю, поможет. Общение с продавцом на телефоне и работа мастера понравились.</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Станислав</p>
                                        <p className={styles.comment}>27.04.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Приехали в оговоренный срок, задачу выполнили как договаривались по телефону, откачка 3х ёмкостей с промывкой. Работа выполнена(с их стороны) и оплачена(с моей стороны) притензий нет, рекомендую.</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
        </section>
    )
}