import React from 'react'
import styles from './Advantages.module.scss'
import { motion } from "framer-motion"
import image_1 from '../../image/Advantages/image_1.svg'
import image_2 from '../../image/Advantages/image_2.svg'
import image_3 from '../../image/Advantages/image_3.svg'
import image_4 from '../../image/Advantages/image_4.svg'
import image_5 from '../../image/Advantages/image_5.svg'
import image_6 from '../../image/Advantages/image_6.svg'

const titleAnimation = {
    hidden: {
        opacity: 0,
    },

    visible: {
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


export const Advantages = () => {

    return (
        <motion.section 
        className={styles.main} 
        id='Advantages'
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.1, once: true}}
        >
        <div className={styles.container}>
            <motion.h2 className={styles.title} variants={titleAnimation}><span>6 ПРИЧИН</span> ПОЧЕМУ ИМЕННО&#160;МЫ?</motion.h2>
            <motion.ul className={styles.list} variants={boxAnimation}>
                <li className={styles.item}>
                    <div className={styles.item_container_red}>
                        <div className={styles.box_red} >
                            <img className={styles.icon} src={image_1} alt='телефон'/>
                            <h3 className={styles.item_title}>Собственный автопарк</h3>
                            <p className={styles.item_text}>Илососы с размывом, илососы комбинированные, илососы объемом 7м3, 10м3, 12м3, 15м3, каналопромывочные машины</p>
                        </div>
                    </div>
                    <div className={styles.item_background}></div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_container}>
                        <div className={styles.box} >
                            <img className={styles.icon} src={image_2} alt='телефон'/>
                            <h3 className={styles.item_title}>Доступные цены</h3>
                            <p className={styles.item_text}>Мы не посредники – мы исполнители. Обращаясь к нам напрямую, Вы получаете самую низкую цену, избегая услуг посредников.</p>
                        </div>
                    </div>
                    <div className={styles.item_background}></div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_container_red}>
                        <div className={styles.box_red} >
                            <img className={styles.icon} src={image_3} alt='телефон'/>
                            <h3 className={styles.item_title}>Многолетний опыт</h3>
                            <p className={styles.item_text}>Наши  специалисты с многолетним опытом и обширными знаниями выполняют работы любой сложности согласно установленным нормам и правилам.</p>
                        </div>
                    </div>
                    <div className={styles.item_background}></div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_container}>
                        <div className={styles.box} >
                            <img className={styles.icon} src={image_4} alt='телефон'/>
                            <h3 className={styles.item_title}>Профессиональный подход</h3>
                            <p className={styles.item_text}>Проконсультируем и подберем самое выгодное предложения для вас, гибкая система скидок, специальные условия для постоянных клиентов.</p>
                        </div>
                    </div>
                    <div className={styles.item_background}></div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_container_red}>
                        <div className={styles.box_red} >
                            <img className={styles.icon} src={image_5} alt='телефон'/>
                            <h3 className={styles.item_title}>Оперативный выезд</h3>
                            <p className={styles.item_text}>Стандарты работы нашей компании, исключают откладывание заявок на вторую очередь. Мы оперативно приедем и окажем всю необходимую помощь.</p>
                        </div>
                    </div>
                    <div className={styles.item_background}></div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_container}>
                        <div className={styles.box} >
                            <img className={styles.icon} src={image_6} alt='телефон'/>
                            <h3 className={styles.item_title}>Удобный расчет</h3>
                            <p className={styles.item_text}>Работаем за наличные и безналичный расчет, НДС. Заключаем договора с компаниями на разовые и постоянные услуги.</p>
                        </div>
                    </div>
                    <div className={styles.item_background}></div>
                </li>
            </motion.ul>
        </div>   
    </motion.section>
    )
}