import React from 'react'
import styles from './Services.module.scss'
import { motion } from "framer-motion"
import icon_1 from '../../image/Services/img_1.webp'
import icon_2 from '../../image/Services/img_2.webp'
import icon_3 from '../../image/Services/img_3.webp'
import icon_4 from '../../image/Services/img_4.webp'
import icon_5 from '../../image/Services/img_5.webp'
import icon_6 from '../../image/Services/img_6.webp'
import icon_7 from '../../image/Services/img_7.webp'
import icon_8 from '../../image/Services/img_8.webp'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}

export const Services = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main} 
            id='Services'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.02, once: true}}
            >
                <div className={styles.box}>
                    <motion.h2 className={styles.title} variants={titleAnimation}><span>услуги</span> которые мы&#160;предлагаем</motion.h2>
                    <motion.ul className={styles.list} variants={boxAnimation}>
                        <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_1} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Откачка, очистка автомоек и жироуловителей</h3>
                                <button className={styles.button}  type='button' onClick={isOpen}>ЗАКАЗАТЬ</button>
                            </div>
                        </li>
                        <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_2} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Откачка, очистка септиков и выгребных ям и колодцев</h3>
                                <button className={styles.button} type='button' onClick={isOpen}>ЗАКАЗАТЬ</button>
                            </div>
                        </li>
                        <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_3} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Обслуживание промышленных предприятий</h3>
                                <button className={styles.button} type='button' onClick={isOpen}>ЗАКАЗАТЬ</button>
                            </div>
                        </li>
                        <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_4} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Устранение засоров, промывка и чистка труб</h3>
                                <button className={styles.button} type='button' onClick={isOpen} >ЗАКАЗАТЬ</button>
                            </div>
                        </li>
                        <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_5} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Аренда илососа и каналопромывочной машины</h3>
                                <button className={styles.button} type='button' onClick={isOpen}>ЗАКАЗАТЬ</button>
                            </div>
                        </li>
                        <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_6} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Осушение, откачка различных помещений и подвалов</h3>
                                <button className={styles.button} type='button' onClick={isOpen}>ЗАКАЗАТЬ</button>
                            </div>
                        </li>
                        <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_7} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Осушение, откачка водоемов, ям, котлованов и бассейнов</h3>
                                <button className={styles.button} type='button' onClick={isOpen}>ЗАКАЗАТЬ</button>
                            </div>
                        </li>
                        <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_8} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Откачка, очистка, обслуживание КНС, ГНБ</h3>
                                <button className={styles.button} type='button' onClick={isOpen} >ЗАКАЗАТЬ</button>
                            </div>
                        </li>
                    </motion.ul>
                </div>
        </motion.section>
        
    )
}