import React from 'react'
import { motion } from "framer-motion"
import styles from './FindPrice.module.scss'
import image from '../../image/FindPrice/img.png'

const boxAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}


export const FindPrice = () => {


    return (
        <motion.div 
            className={styles.main}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}}
                >
                <div className={styles.container}>
                    <motion.div className={styles.box} variants={boxAnimation}>
                        <h2 className={styles.title}><span>Позвоните</span> и&#160;узнайте стоимость</h2>
                        <p className={styles.text}>в течение <span>5 минут</span></p>
                        <p className={styles.phone}>+7(985)838-40-70</p>
                    </motion.div>
                    <motion.img className={styles.image} src={image} alt='металл' variants={imageAnimation}/>
                </div>
            </motion.div> 
    )
}