import React from 'react'
import styles from './Scheme.module.scss'
import { motion } from "framer-motion"
import image_1 from '../../image/Scheme/image_1.svg'

export const Scheme = () => {

    const titleAnimation = {
        hidden: {
            y: 200,
            opacity: 0,
        },
    
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .5 }
        }
    }
    
    const boxAnimation = {
        hidden: {
            y: 500,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .9}
        } 
    }

    return (
        <motion.section 
            className={styles.main}
            id='Deadlines' 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
        >
            <div className={styles.container}>
            <motion.h2 className={styles.title} variants={titleAnimation}><span>Как мы работаем</span> с&#160;клиентами?</motion.h2>
            <motion.ul className={styles.list} variants={boxAnimation}>
                <li className={styles.item}>
                    <div className={styles.item_container}>
                        <div className={styles.box} >
                            <div className={styles.item_box}>
                                <img className={styles.icon} src={image_1} alt='этап'/>
                                <p className={styles.number}>01</p>
                            </div>
                            <h3 className={styles.item_title}>Заявка</h3>
                            <p className={styles.item_text}>Вы оставляете электронную заявку на сайте, электронной почте, мессенджере или звоните нам по телефону.</p>
                        </div>
                    </div>
                    <div className={styles.item_background}></div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_container_red}>
                        <div className={styles.box_red} >
                            <div className={styles.item_box}>
                                <img className={styles.icon} src={image_1} alt='этап'/>
                                <p className={styles.number}>02</p>
                            </div>
                            <h3 className={styles.item_title}>Консультация</h3>
                            <p className={styles.item_text}>Уточняем особенности вашего обьекта, обьем работ, срочность вызова и производим расчет стоимости работ.</p>
                        </div>
                    </div>
                    <div className={styles.item_background}></div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_container}>
                        <div className={styles.box} >
                        <div className={styles.item_box}>
                            <img className={styles.icon} src={image_1} alt='этап'/>
                            <p className={styles.number}>03</p>
                        </div>
                        <h3 className={styles.item_title}>Оплата</h3>
                        <p className={styles.item_text}>В случае, если вы являетесь юридическим лицом, оплачиваете сто процентов оговоренной стоимости работ.</p>
                        </div>
                    </div>
                    <div className={styles.item_background}></div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_container_red}>
                        <div className={styles.box_red} >
                            <div className={styles.item_box}>
                                <img className={styles.icon} src={image_1} alt='этап'/>
                                <p className={styles.number}>04</p>
                            </div>
                            <h3 className={styles.item_title}>Выезд</h3>
                            <p className={styles.item_text}>В назначенные дату и время осуществляется выезд необходимой техники и специалистов по заявленному адресу.</p>
                        </div>
                    </div>
                    <div className={styles.item_background}></div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_container}>
                        <div className={styles.box} >
                            <div className={styles.item_box}>
                                <img className={styles.icon} src={image_1} alt='этап'/>
                                <p className={styles.number}>05</p>
                            </div>
                            <h3 className={styles.item_title}>Работы</h3>
                            <p className={styles.item_text}>Самый дешевый вариант - сразу сделать хорошо! Мы профессионально и качественно выполняем все оговоренные работы.</p>
                        </div>
                    </div>
                    <div className={styles.item_background}></div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_container_red}>
                        <div className={styles.box_red} >
                            <div className={styles.item_box}>
                                <img className={styles.icon} src={image_1} alt='этап'/>
                                <p className={styles.number}>06</p>
                            </div>
                            <h3 className={styles.item_title}>Оплата</h3>
                            <p className={styles.item_text}>Вы оплачиваете остаток стоимости выполненных работ, и мы предоставляем гарантию на проведенные нами работы.</p>
                        </div>
                    </div>
                    <div className={styles.item_background}></div>
                </li>         
            </motion.ul>
            </div>   
        </motion.section>
        
        
    )
}
